<!--
  TODO: Needs more work to be role="radiogroup" compliant
  https://www.w3.org/TR/2016/WD-wai-aria-practices-1.1-20160317/examples/radio/radio.html
-->

<template>
  <fieldset>
    <ul class="grid grid-flow-col auto-rows-fr space-x-4" role="radiogroup" :aria-labelledby="labelledby">
      <li
        v-for="(value, label) in options"
        :key="value"
        tabindex="0"
        role="radio"
        aria-checked="true"
        class="
          group relative rounded-lg shadow-sm cursor-pointer
          focus:outline-none focus:shadow-outline-blue
        "
        @click="$emit('input', value)"
      >
        <div
          class="
            h-full flex items-center justify-center px-6 py-4
            rounded-lg border border-gray-300 bg-white text-lg text-center
            hover:border-gray-400 group-focus:border-blue-300
          "
          :class="selectedStyle(value).label"
        >
          {{label}}
        </div>
        <!-- On: "border-indigo-500", Off: "border-transparent" -->
        <div
          class="absolute inset-0 rounded-lg border-2 pointer-events-none"
          :class="selectedStyle(value).highlight"
        ></div>
      </li>
    </ul>
  </fieldset>
</template>

<script>
  import { computed } from 'vue'

  export default {
    name: "BasePanelSelect",

    props: {
      value: {
        required: true,
      },
      labelledby: {
        type: String,
        required: true,
      },
      options: {
        type: Object,
        required: true,
      }
    },

    setup(props) {
    },

    methods: {
      selectedStyle(optionValue) {
        const isSelected = this.value === optionValue

        return {
          label: isSelected ? 'font-semibold' : 'font-normal',
          highlight: isSelected ? 'border-indigo-500' : 'border-transparent',
        }
      }
    },
  }
</script>
