import Vue from 'vue'
import Axios from './plugins/Axios'
import Bugsnag from './plugins/Bugsnag'
import { registerBaseComponents } from '../vue/lib/registerBaseComponents'

/*
  NOTE: Until Vue 3, we have to use Composition API first thing to prevent it from running after all of your included files
  https://stackoverflow.com/questions/61885716/uncaught-error-vue-composition-api-must-call-vue-useplugin-before-using-any/61907559#61907559
*/
Vue.use(Bugsnag)
Vue.use(Axios)

registerBaseComponents(Vue)

export { Vue }
