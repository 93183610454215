import axios from 'axios'
import _get from 'lodash.get'

function setAxiosAuthHeader({ jwt }) {
  const bearerToken = `Bearer ${jwt}`

  axios.defaults.headers = {
    Authorization: bearerToken
  }
}

const AxiosPlugin = {
  install: function (Vue, { hydrate = true } = {}) {
    Vue.prototype.$axios = axios

    if (hydrate) {
      const authn = _get(
        gon,
        'initial_app_state.authentication.data.attributes',
        {}
      )

      if (authn.is_authenticated && authn.jwt) {
        setAxiosAuthHeader({ jwt: authn.jwt })
      }
    }
  }
}

export default AxiosPlugin

export { setAxiosAuthHeader }
