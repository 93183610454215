<template>
  <RoundsyLogo class="logo w-48" :class="variantStyle" alt="logo" />
</template>

<script type="text/javascript">
  import { computed } from 'vue'
  import RoundsyLogo from '../../assets/images/logo.svg'

  export default {
    name: "BaseLogo",

    props: {
      variant: {
        type: String,
        default: 'default',
        validator(v) {
          return ['default', 'inverted'].indexOf(v) !== -1
        },
      },
    },

    components: {
      RoundsyLogo,
    },

    setup(props) {
      const variantStyle = computed(() => {
        if (props.variant === 'default') return 'text-brand'
        if (props.variant === 'inverted') return 'text-white'
      })

      return {
        variantStyle,
      }
    },
  }
</script>

<style lang="sass" scoped>
  .logo
    fill: currentColor
</style>
