<template>
  <label class="flex items-center">
    <input
      :id="id"
      type="checkbox"
      class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
      :disabled="disabled"
      :checked="value"
      @input="$emit('input', $event.target.checked)"
    >
    <span class="ml-2 block text-lg leading-5 text-gray-900">
      {{label}}
    </span>
  </label>
</template>

<script>
  import { computed } from 'vue'

  export default {
    name: "BaseCheckbox",

    props: {
      id: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Boolean,
        required: true,
      }
    },
  }
</script>
