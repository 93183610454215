<template>
  <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4" :class="variantStyle">
    <slot />
  </span>
</template>

<script type="text/javascript">
  import { computed } from 'vue'

  export default {
    name: "BaseBadge",

    props: {
      variant: {
        type: String,
        default: 'green',
        validator(v) {
          return ['green', 'yellow'].indexOf(v) !== -1
        },
      },
    },

    setup(props) {
      const variantStyle = computed(() => {
        if (props.variant === 'green') return 'bg-green-100 text-green-800'
        if (props.variant === 'yellow') return 'bg-yellow-100 text-yellow-800'
      })

      return {
        variantStyle,
      }
    },
  }
</script>
