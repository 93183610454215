<template>
  <div class="fixed z-100 inset-0 overflow-y-auto"">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75" @click="close"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-label="ariaTitle">
          <CloseIcon
            class="absolute text-brand top-2 right-2 cursor-pointer h-8 w-8"
            @click="close"
          />
          <div class="pt-12">
            <slot :close="close" />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '../../assets/images/icons/icon-x.svg'

export default {
  name: "BaseModal",

  components: {
    CloseIcon,
  },

  props: {
    ariaTitle: {
      type: String,
      required: true,
    }
  },

  setup(props, { emit }) {
    function close() {
      emit('close')
    }

    return {
      close,
    }
  }
}
</script>
