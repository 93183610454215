<template>
  <label class="block">
    <div class="block text-lg font-medium leading-5 text-gray-700">{{label}}</div>
    <DatePicker
      type="time"
      value-type="date"
      :value="value"
      :input-attr="{id: id}"
      :disabled="disabled"
      :open.sync="open"
      @change="handleChange"
      :show-second="false"
      format="hh:mm:A"
      class="mt-2"
    />
  </label>
</template>

<script>
  import { ref } from 'vue'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'

  export default {
    name: "BaseTimePicker",

    components: {
      DatePicker,
    },

    props: {
      id: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: 'Select Time',
      },
      value: {
        type: Date,
        required: true,
      }
    },

    setup(props, { emit }) {
      const open = ref(false)

      const handleChange = function(value, type) {
        if (type === 'ampm') {
          this.open = false;
        }
        emit('input', value)
      }

      return {
        open,
        handleChange,
      }
    }
  }
</script>

<style lang="sass">
  .mx-datepicker
    width: 100% !important

  .mx-time-column .mx-time-item.active
    color: #633FF2 !important
</style>
