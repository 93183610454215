<template>
  <label class="block">
    <div class="block text-lg font-medium leading-5 text-gray-700">{{label}}</div>
    <DatePicker
      value-type="date"
      :value="value"
      :input-attr="{id: id}"
      :disabled="disabled"
      @change="handleChange"
      class="mt-2"
    />
  </label>
</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'

  export default {
    name: "BaseDatePicker",

    components: {
      DatePicker,
    },

    props: {
      id: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: 'Select Date',
      },
      value: {
        type: Date,
        required: true,
      }
    },

    setup(props, { emit }) {
      const handleChange = function(value) {
        emit('input', value)
      }

      return {
        handleChange,
      }
    }
  }
</script>

<style lang="sass">
  .mx-datepicker
    width: 100% !important

  .mx-calendar-content .cell.active
    background-color: #633FF2 !important

  .mx-table-date .today
    color: #633FF2 !important
</style>
