import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import _get from 'lodash.get'

const BugsnagPlugin = {
  install: function (Vue, options = {}) {
    let $errors

    if (process.env.NODE_ENV === 'production') {
      const apiKey = _get(gon, '_env_vars.bugsnag_browser_key')

      Bugsnag.start({
        apiKey,
        plugins: [new BugsnagPluginVue()]
      })

      Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
      $errors = Bugsnag
    } else {
      $errors = {
        notify(error) {
          console.error(
            '⚠️ Bugsnag is not enabled. Logging to console instead.'
          )
          console.error(error, process.env.BUGSNAG_BROWSER_KEY)
        },
        use() {}
      }

      window.$errors = $errors
    }

    Vue.prototype.$errors = $errors
  }
}

export default BugsnagPlugin
