<template>
  <label class="block">
    <div class="block text-lg font-medium leading-5 text-gray-700">{{label}}</div>
    <div class="mt-2 relative rounded-md shadow-sm">
      <input
        :id="id"
        class="form-input block w-full sm:text-sm sm:leading-5"
        :placeholder="placeholder"
        :disabled="disabled"
        :autofocus="autofocus"
        :value="value"
        @input="$emit('input', $event.target.value)"
      >
    </div>
  </label>
</template>

<script>
  import { computed } from 'vue'

  export default {
    name: "BaseTextInput",

    props: {
      id: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        required: false,
      },
      autofocus: {
        type: Boolean,
        default: false,
      },
      value: {
        type: String,
        required: true,
      }
    },
  }
</script>
