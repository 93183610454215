<template>
  <select
    class="form-input text-sm"
    :value="value"
    :aria-labelledby="labelledby"
    @input="$emit('input', $event.target.value)"
  >
    <option
      v-for="(optionValue, optionLabel) in options"
      :key="optionValue"
      :value="optionValue"
      >{{ optionLabel }}</option
    >
  </select>
</template>

<script type="text/javascript">
export default {
  name: 'BaseDropdownSelect',

  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true
    },
    labelledby: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  }
}
</script>
