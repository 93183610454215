<!--
  TODO: button classes should be moved to their own component via @apply
  directive but we can't do this until we can setup postcss
-->

<template>
  <div>
    <component
      :is="tag"
      :class="variantStyle"
      @click="$emit('click', $event)"
      v-bind="$attrs"
    >
      <slot />
    </component>
  </div>
</template>

<script type="text/javascript">
  import { computed } from 'vue'

  export default {
    name: "BaseButton",

    props: {
      link: {
        type: Boolean,
        default: false,
      },
      variant: {
        type: String,
        default: 'default',
        validator(v) {
          return ['default', 'secondary', 'inverted', 'danger'].indexOf(v) !== -1
        },
      },
      size: {
        type: String,
        default: 'base',
        validator(v) {
          return ['base', 'sm'].indexOf(v) !== -1
        },
      }
    },

    setup(props) {
      const tag = computed(() => {
        return props.link ? 'a' : 'button'
      })

      const variantStyle = computed(() => {
        let style = `
          w-full flex items-center justify-center border border-transparent
          leading-5 font-medium rounded-md
          focus:outline-none focus:shadow-outline-gray focus:border-gray-700
          transition duration-150 ease-in-out
        `;

        if (props.variant === 'default') {
          style = style + `
            text-white bg-brand

            hover:text-gray-100
            active:bg-indigo-700
            hover:text-gray-100 hover:shadow-outline-gray
          `
        }

        if (props.variant === 'secondary') {
          style = style + `
            text-gray-400 bg-transparent
            hover:text-gray-800
            active:text-purple-700
          `
        }

        if (props.variant === 'inverted') {
          style = style + `
            text-brand bg-white
            hover:text-purple-700 hover:shadow-outline-gray
            active:text-purple-900 active:bg-gray-300
          `
        }

        if (props.variant === 'danger') {
          style = style + `
            text-white bg-red-800
            hover:text-gray-100 hover:shadow-outline-gray
            active:text-red-900 active:bg-gray-300
          `
        }

        if (props.size === 'base') {
          style = style + `
            text-lg
            px-6 py-4
          `
        }

        if (props.size === 'sm') {
          style = style + `
            text-base
            px-6 py-2
          `
        }

        return style
      })

      return {
        tag,
        variantStyle,
      }
    },
  }
</script>
