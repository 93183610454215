import BaseAlert from '../components/Base/BaseAlert.vue'
import BaseBadge from '../components/Base/BaseBadge.vue'
import BaseButton from '../components/Base/BaseButton.vue'
import BaseCheckbox from '../components/Base/BaseCheckbox.vue'
import BaseDatePicker from '../components/Base/BaseDatePicker.vue'
import BaseDropdownSelect from '../components/Base/BaseDropdownSelect.vue'
import BaseLoader from '../components/Base/BaseLoader.vue'
import BaseLogo from '../components/Base/BaseLogo.vue'
import BaseModal from '../components/Base/BaseModal.vue'
import BasePageContent from '../components/Base/BasePageContent.vue'
import BasePanelSelect from '../components/Base/BasePanelSelect.vue'
import BaseTextInput from '../components/Base/BaseTextInput.vue'
import BaseTimePicker from '../components/Base/BaseTimePicker.vue'

const baseComponents = {
  BaseAlert,
  BaseBadge,
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseDropdownSelect,
  BaseLoader,
  BaseLogo,
  BaseModal,
  BasePageContent,
  BasePanelSelect,
  BaseTextInput,
  BaseTimePicker,
}

function registerBaseComponents(vue) {
  for (const [name, component] of Object.entries(baseComponents)) {
    vue.component(name, component)
  }

  return vue;
}

export {
  registerBaseComponents
}
